import React from "react";

class NodeStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      utxoData: [],
      addressData: []
    };
  }

  componentDidMount() {
    Promise.all([
      fetch('https://explorer.hydrachain.org/api/address/HRAS35Sp8R3BUpWKJgFZKYsYGc4vBkjpJx/utxo'),
      fetch('https://explorer.hydrachain.org/api/address/HRAS35Sp8R3BUpWKJgFZKYsYGc4vBkjpJx')
    ])
    .then(([res1, res2]) => { 
      return Promise.all([res1.json(), res2.json()]) 
    })
        .then(([res1, res2]) => {
          this.setState({
            isLoaded: true,
            utxoData: res1,
            addressData: res2
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, utxoData, addressData } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
      <div>
          <div className="row">
            <div className="col-lg-12 mb30">
                <span className="box-url">
                    <span className="box-url-label xs-hide">AWS Node</span>
                    <img src="./img/aws-logo.png" alt="" className="mb20 xs-text-center"/>
                    <div className="row">
                      <div className="col-md-2 col-xs-12 text-center">
                        <h4><u>Address</u></h4>
                        <h6 className="text-break">HRAS35Sp8R3BUpWKJgFZKYsYGc4vBkjpJx</h6>
                      </div>
                      <div className="col-md-2 col-xs-12 text-center"><h4><u>Fee</u></h4>
                        <h6 className="text-center">5%</h6>
                      </div>
                      <div className="col-md-2 col-xs-12 text-center">
                        <h4><u>Min. UTXO Size</u></h4>
                        <h6 className="text-center">5</h6>
                      </div>
                      <div className="col-md-2 col-xs-12 text-center">
                        <h4><u>Total UTXO's</u></h4>
                        <h6 className="text-center">{utxoData.length}</h6>
                      </div>
                      <div className="col-md-2 col-xs-12 text-center">
                        <h4><u>Maturing UTXO's</u></h4>
                        <h6 className="text-center">{utxoData.filter(item => item.confirmations <2000).length}</h6>
                      </div>
                      <div className="col-md-2 col-xs-12 text-center">
                        <h4><u>Total mined blocks</u></h4>
                        <h6 className="text-center">{addressData.blocksMined}</h6>
                      </div>
                    </div>                  
                    <div className="row">
                      <div className="col-12">
                        <p>The node is currently running on an AWS USA East t3.micro EC2 instance. 
                          I also have maintenance nodes setup on local machines for times when the 
                          AWS instance needs to be taken offline for maintenance. This allows for 
                          near 100% uptime of the node.</p>
                      </div>
                    </div>                    
                </span>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default NodeStatus;